import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

const Home = () => {
  return (
    <Box
      id="home"
      sx={{
        width: "100%",
        padding: { xs: "50px 10px", sm: "50px 20px" },
      }}
    >
      <Stack direction="column" alignItems="center">
        <img
          src="./images/bully-brush.png"
          alt="Bully"
          style={{ width: "60%", maxWidth: "320px" }}
        />

        <Typography
          sx={{
            height: { xs: "42px", sm: "70px" },
            fontFamily: "NicoMoji",
            fontSize: { xs: "36px", sm: "60px" },
            background: "linear-gradient(180deg, #C5D4E9 0%, #B9C7E1 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          THE WORLD OF
        </Typography>
        <Typography
          sx={{
            height: { xs: "60px", sm: "90px" },
            fontFamily: "NicoMoji",
            fontSize: { xs: "54px", sm: "80px" },
            background: "linear-gradient(180deg, #FE8826 0%, #F3122B 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          BULLY
        </Typography>
        <Typography
          sx={{
            marginTop: "20px",
            fontSize: { xs: "14px", sm: "18px" },
            color: "#D7D7D7",
            textAlign: "center",
          }}
        >
          BULLY is here to become an icon, with a unique and innovative
          ecosystem that rewards engagement and community.
          <br />
          An experience that sells itself.
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
            marginTop: "20px",
            ".MuiButton-root": {
              width: "40%",
              maxWidth: "180px",
              height: "60px",
              padding: "5px",
              borderRadius: "30px",
              background: "linear-gradient(180deg, #C5D4E9 0%, #B9C7E1 100%)",
              alignItems: "center",
              gap: "10px",
              fontSize: { xs: "14px", sm: "18px" },
              fontWeight: "700",
            },
            img: {
              width: "50px",
            },
          }}
        >
          <Button
            href="https://t.me/tap7he8u11"
            target="_blank"
            sx={{
              borderTopLeftRadius: "0 !important",
              borderBottomLeftRadius: "60px !important",
              justifyContent: "end",
              color: "#36ADE1",
            }}
          >
            JOIN
            <img src="./images/telegram-logo.png" alt="Telegram" />
          </Button>
          <Button
            href="https://x.com/TapTheBull"
            target="_blank"
            sx={{
              borderTopRightRadius: "0 !important",
              borderBottomRightRadius: "60px !important",
              justifyContent: "start",
              color: "#000000",
            }}
          >
            <img src="./images/x-logo.png" alt="X" />
            FOLLOW
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Home;
