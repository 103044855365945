import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const Roadmap = () => {
  return (
    <Box
      id="roadmap"
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          borderTopLeftRadius: "100vh",
          background: "linear-gradient(180deg, #C5D4E9 0%, #B9C7E1 100%)",
          zIndex: 0,
        }}
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        sx={{
          position: "relative",
          zIndex: 1,
          img: {
            width: { xs: "80%", sm: "40%" },
            height: "fit-content",
            margin: { xs: "0 20px", sm: "0 20px 50px" },
          },
        }}
      >
        <img src="./images/bull-rocket.png" alt="Rocket" />
        <Stack
          direction="column"
          alignItems="end"
          gap="5px"
          sx={{
            padding: { xs: "20px 20px 50px", sm: "20px 50px" },
            ".roadmap-container": {
              height: "60px",
              borderTopLeftRadius: "54px",
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "48px",
              borderBottomLeftRadius: "15px",
              padding: "5px 5px 5px 50px",
              gap: "10px",
              ".MuiTypography-root": {
                fontSize: { xs: "16px", sm: "20px" },
                color: "#000000",
                fontWeight: "500",
              },
            },
            ".roadmap-phase": {
              width: "50px",
              minWidth: "50px",
              height: "50px",
              color: "#D9D9D9 !important",
              fontSize: "28px !important",
              fontWeight: "600 !important",
              background: "#203040 !important",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "15px",
              borderBottomLeftRadius: "0",
              alignContent: "center",
              textAlign: "center",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "NicoMoji",
              fontSize: { xs: "42px", sm: "60px" },
              color: "#000000",
            }}
          >
            Roadmap
          </Typography>
          <Stack
            className="roadmap-container"
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{
              background:
                "linear-gradient(180deg, rgba(224,128,64,0.5) 0%, rgba(224,128,64,0.8) 100%)",
            }}
          >
            <Typography>Initial Development and Community Building</Typography>
            <Typography className="roadmap-phase">1</Typography>
          </Stack>
          <Stack
            className="roadmap-container"
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{
              background:
                "linear-gradient(180deg, rgba(224,64,128,0.5) 0%, rgba(224,64,128,0.8) 100%)",
            }}
          >
            <Typography>Game Development and Presale Preparation</Typography>
            <Typography className="roadmap-phase">2</Typography>
          </Stack>
          <Stack
            className="roadmap-container"
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{
              background:
                "linear-gradient(180deg, rgba(128,224,64,0.5) 0%, rgba(128,224,64,0.8) 100%)",
            }}
          >
            <Typography>Game Launch</Typography>
            <Typography className="roadmap-phase">3</Typography>
          </Stack>
          <Stack
            className="roadmap-container"
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{
              background:
                "linear-gradient(180deg, rgba(128,64,224,0.5) 0%, rgba(128,64,224,0.8) 100%)",
            }}
          >
            <Typography>Presale Execution and Token Listing</Typography>
            <Typography className="roadmap-phase">4</Typography>
          </Stack>
          <Stack
            className="roadmap-container"
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{
              background:
                "linear-gradient(180deg, rgba(64,224,128,0.5) 0%, rgba(64,224,128,0.8) 100%)",
            }}
          >
            <Typography>Growth and Expansion</Typography>
            <Typography className="roadmap-phase">5</Typography>
          </Stack>
          <Stack
            className="roadmap-container"
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{
              background:
                "linear-gradient(180deg, rgba(64,128,224,0.5) 0%, rgba(64,128,224,0.8) 100%)",
            }}
          >
            <Typography>Sustainability and Long-Term Development</Typography>
            <Typography className="roadmap-phase">6</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Roadmap;
