import { Box, Container } from "@mui/material";

import Header from "./layouts/header";
import Home from "./layouts/home";
import About from "./layouts/about";
import Tokenomics from "./layouts/tokenomics";
import Roadmap from "./layouts/roadmap";
import Footer from "./layouts/footer";

function App() {
  return (
    <>
      <Container
        sx={{
          position: "relative",
          height: "100vh",
          padding: "0 !important",
          background:
            "linear-gradient(180deg, rgba(64,128,224,0.5) 0%, rgba(64,128,224,0.2) 100%)",
        }}
      >
        <Header />
        <Box
          sx={{
            position: "absolute",
            top: "100px",
            width: "100%",
            height: "calc(100vh - 100px)",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <Home />
          <About />
          <Tokenomics />
          <Roadmap />
          <Footer />
        </Box>
      </Container>
    </>
  );
}

export default App;
