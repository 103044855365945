import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const About = () => {
  return (
    <Box
      id="about"
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          borderBottomRightRadius: "100vh",
          background: "linear-gradient(180deg, #C5D4E9 0%, #B9C7E1 100%)",
          zIndex: 0,
        }}
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "end", sm: "start" }}
        sx={{
          position: "relative",
          width: "100%",
          zIndex: 1,
          img: {
            width: "40%",
            height: "fit-content",
            margin: { xs: "10px", sm: "50px 20px -50px" },
          },
        }}
      >
        <Stack sx={{ padding: "30px 40px" }}>
          <Typography
            sx={{
              fontFamily: "NicoMoji",
              fontSize: { xs: "42px", sm: "60px" },
            }}
          >
            About
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontSize: { xs: "14px", sm: "18px" },
              fontWeight: "500",
            }}
          >
            Bully is a play-to-earn (P2E) game developed on the Solana
            blockchain, where players engage in daily tasks to earn rewards in
            Bully tokens. The game features a playful bull character that
            players tap to complete various activities and unlock bonuses. Bully
            integrates social engagement by offering additional rewards for
            tasks such as liking the game's Twitter page or inviting friends to
            join the Telegram community.
          </Typography>
          <Typography
            sx={{
              marginTop: "15px",
              textAlign: "justify",
              fontSize: { xs: "14px", sm: "18px" },
              fontWeight: "500",
            }}
          >
            The primary goal of Bully is to provide a fun and interactive way
            for users to engage in blockchain-based gameplay while earning token
            rewards. With a structured and fair token economy, Bully aims to
            create a sustainable ecosystem that benefits both casual gamers and
            committed community members.
          </Typography>
        </Stack>
        <img src="/images/bull.png" alt="Bull" />
      </Stack>
    </Box>
  );
};

export default About;
